.header {
  margin-top: 34px;
  @include mq() {
    margin-top: 60px;
  }
}
.header__logo {
  width: 34%;
  max-width: 320px;
  margin-bottom: 40px;
  @include mq() {
    width: 200px;
    margin-bottom: 80px;
  }

  img {
    vertical-align: bottom;
  }
}