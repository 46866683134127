html {
  font-size: 62.5%;
}

body {
  color: #222;
  font-family: 'Roboto', 'Noto Sans JP', sans-serif;
  font-weight: 400; // or 600
}

ol, ul {
  list-style: none;
}

a {
  color: #222;
  text-decoration: none;
  transition: 0.2s;
  &:hover {
    opacity: 0.7;
  }
}

img {
  max-width: 100%;
}

address {
  font-style: normal;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
  @include mq() {
    width: 80%;
    max-width: 1280px;
  }
}