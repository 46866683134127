.footer {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 100px;
}
.footer_fb {
  margin-bottom: 48px;

  a {
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    background-color: #222;
    padding: 18px 32px;
  }
}