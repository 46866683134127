.p-entryRelationPost {
  font-size: 1.6rem;
   margin: 100px auto 0;
  @include mq() {
    width: 70%;
    max-width: 850px;
  }
}
.p-entryRelationPost__title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 16px;
}
.p-entryRelationPost__list {
  list-style: disc;
  padding-left: 40px;
}