.c-navigation {
  font-size: 1.6rem;
  margin-bottom: 40px;
}
.c-navigation__list {
  display: flex;
  justify-content: space-around;
  
  li {
    text-align: center;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    width: 50%;

    a {
      display: block;
      line-height: 1;
      padding: 10px 0;
      @include mq() {
        padding: 24px 0;
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
.current-menu-parent a,
.current-menu-item a {
  background-color: #f0f0f0;
}