.c-entrylist {
  border-top: 1px dotted #ddd;
   margin: 100px auto 0;
  @include mq() {
    width: 70%;
    max-width: 850px;
  }
}
.c-entrylist__list {
}
.c-entrylist__item {
  border-bottom: 1px dotted #ddd;
  &:nth-child(odd) {
    background-color: #fafafa;
  }
  a {
    display: block;
    padding: 20px 4%;
    @include mq() {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.c-entrylist__title {
  font-size: 2rem;
  font-weight: normal;
  margin-bottom: 6px;
  @include mq() {
    margin-bottom: 0;
    margin-right: 32px;
  }
}
.c-entrylist__category {
  display: inline-block;
  color: #fff;
  font-size: 1.2rem;
  text-indent: .35rem;
  line-height: 1;
  letter-spacing: .35rem;
  padding: 3px 10px 2px;
  border-radius: 100px;
  background-color: #333;

  &--html {
    background-color: #e83e8c;
  }
  &--css {
    background-color: #397300;
  }
}

.p-entry {
  margin: 100px auto 0;
  @include mq() {
    width: 70%;
    max-width: 850px;
  }
}
.p-entry__header {
  margin-bottom: 100px;
}
.p-entry__title {
  font-size: 4rem;
  font-weight: normal;
  margin-bottom: 8px;
}
.p-entry__category {
  display: inline-block;
  color: #fff;
  font-size: 1.2rem;
  text-indent: .35rem;
  line-height: 1;
  letter-spacing: .35rem;
  padding: 3px 10px 2px;
  border-radius: 100px;
  background-color: #333;

  &--html {
    background-color: #e83e8c;
  }
  &--css {
    background-color: #397300;
  }
}
.p-entry__contents {
  font-size: 1.6rem;
  line-height: 2;

  // ====================================
  h1 {
    font-size: 3rem;
    font-weight: normal;
    margin-top: 140px;
    margin-bottom: 24px;
  }
  h2 {
    font-size: 2.2rem;
    margin-top: 100px;
    margin-bottom: 24px;
    border: none;
  }
  h3 {
    font-size: 1.8rem;
    margin-top: 60px;
    margin-bottom: 24px;
  }
  h4 {
    font-size: 1.6rem;
    margin-top: 40px;
    margin-bottom: 18px;
  }

  img {
    display: block;
    margin: 60px auto;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  hr {
    margin: 180px 0;
  }
  code {
    color: #e83e8c;
  }
  pre {
    code {
      color: #93575e;
    }
  }
}